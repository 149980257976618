import 'jquery';

// ie11 polyfills for webpack's code splitting, which babel does not polyfill
// issue @ https://github.com/babel/babel/issues/7402
// fix documentation @ https://babeljs.io/docs/en/babel-plugin-syntax-dynamic-import#working-with-webpack-and-babel-preset-env
import 'core-js/modules/es.array.iterator';
import 'core-js/modules/es.promise';

// polyfills & other plugins
import( /* webpackChunkName: "tablesaw" */ 'tablesaw/dist/stackonly/tablesaw.stackonly.jquery');
// components
import( /* webpackChunkName: "components" */ './components.js');
// global
import( /* webpackChunkName: "global.chunk" */ './global.js');

if (window.isCollectionPage) {
  import( /* webpackChunkName: "search" */ './components/search.js');
}